import React from 'react';
import clsx from 'clsx';

import Message from 'modules/theme/images/carousel/brand-text.svg';

const BrandText: React.FunctionComponent = () => {
  const classNames = clsx('carousel-brand-text', 'container');

  return (
    <div className={classNames}>
      <Message />
      <h1 className="visually-hidden">Precision Digital Engineering</h1>
      <h2>
        We <strong>deliver online projects</strong> for clients with{' '}
        <strong>big ambitions</strong>
      </h2>
    </div>
  );
};

export default React.memo(BrandText);
