import React from 'react';

import { LazyloadBackground, Image } from 'components/Image';
import { LinkColour } from 'components/Hyperlink';
import PageLink from 'components/Hyperlink/PageLink';

import './styles.scss';

type CaseStudyCtaProps = {
  clientName?: string | null;
  description?: string | null;
  image?: {
    alt: string | null;
    image: readonly (Image | null)[] | null;
  } | null;
  slug?: string | null;
};

const CaseStudyItem: React.FunctionComponent<CaseStudyCtaProps> = ({
  clientName,
  description,
  image,
  slug,
}) => {
  return (
    <article className="case-study-item">
      <div>
        {image && <LazyloadBackground image={image.image} local={false} />}
      </div>
      <div className="container">
        <div>
          <h3>{clientName}</h3>
          {description && <p>{description}</p>}
          {slug && (
            <PageLink colour={LinkColour.RED} slug={slug}>
              View Case Study
            </PageLink>
          )}
        </div>
      </div>
    </article>
  );
};

export default React.memo(CaseStudyItem);
