import React from 'react';
import clsx from 'clsx';

export type CarouselChildProps = {
  active: boolean;
  children: React.ReactNode;
};

const CarouselChild: React.FunctionComponent<CarouselChildProps> = ({
  active,
  children,
}) => {
  const classNames = clsx('carousel-child', active && 'active');
  return <li className={classNames}>{children}</li>;
};

export default React.memo(CarouselChild);
