import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout, { ILayout } from 'modules/layout';
import Meta, { Font } from 'modules/meta';
import Template from './Template';

export const query = graphql`
  query Homepage {
    contentfulSiteConfig {
      ...siteConfig
    }
    contentfulHomepage {
      ...carousel
      ...caseStudyCtaHomepage
      ...contactCtaHomepage
      ...logoListHomepage
      ...metaHomepage
      ...showReelHomepage
      ...servicesSectionHomepage
    }
  }
`;

const Page: React.FunctionComponent<PageProps<Queries.HomepageQuery>> &
  ILayout = ({ data }) => {
  return <Template {...data} />;
};

Page.Layout = Layout;
export default Page;

export const Head: React.FunctionComponent<
  PageProps<Queries.HomepageQuery>
> = ({ data, location }) => {
  return (
    <Meta
      defaultMeta={data.contentfulSiteConfig?.defaultMeta}
      fonts={[Font.BOLD, Font.REGULAR, Font.SEMIBOLD]}
      meta={data.contentfulHomepage?.meta}
      pageTitle={null}
      pathname={location.pathname}
    />
  );
};
