import React from 'react';
import { graphql } from 'gatsby';

import {
  LazyloadBackground,
  LazyloadBackgroundProps,
  PictureProps,
} from 'components/Image';
import { LinkColour } from 'components/Hyperlink';
import { YTVideo } from './YTVideo';
import PageLink from 'components/Hyperlink/PageLink';

import './styles.scss';

export const showReelHomepageFragment = graphql`
  fragment showReelHomepage on ContentfulHomepage {
    showReelReference {
      backgroundImage {
        alt
        image {
          mediaQuery
          quality
          url
        }
      }
      heading
      placeholderImage {
        alt
        image {
          mediaQuery
          quality
          url
        }
      }
      slug
      title
      videoId
    }
  }
`;

type ShowReelProps = {
  backgroundImage?: Omit<LazyloadBackgroundProps, 'local'> | null;
  heading?: string | null;
  placeholderImage?: Omit<PictureProps, 'local'> | null;
  slug?: string | null;
  title?: string | null;
  videoId?: string | null;
};
const ShowReel: React.FunctionComponent<ShowReelProps> = ({
  backgroundImage,
  heading,
  placeholderImage,
  slug,
  title,
  videoId,
}) => {
  return (
    <section className="feature-showreel">
      {backgroundImage && (
        <LazyloadBackground
          image={backgroundImage.image}
          local={false}
          tag="span"
        />
      )}
      <div className="container">
        <h3>{heading}</h3>
        <p>{title}</p>
      </div>
      {videoId && (
        <YTVideo placeholderImage={placeholderImage} videoId={videoId} />
      )}
      <div className="container">
        {slug && (
          <PageLink colour={LinkColour.BLUE} slug={slug}>
            View Case Study
          </PageLink>
        )}
      </div>
    </section>
  );
};

export default React.memo(ShowReel);
