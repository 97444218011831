import React from 'react';
import { graphql } from 'gatsby';

import CarouselContainer from './CarouselContainer';
import Slide, { SlideProps } from './Slide';

import './styles.scss';

export const carouselFragment = graphql`
  fragment carousel on ContentfulHomepage {
    carouselReference {
      carousel {
        slides {
          description
          images {
            alt
            image {
              mediaQuery
              quality
              url
            }
          }
          title
          slug
        }
      }
    }
  }
`;

type CarouselProps = {
  carousel?: {
    slides: readonly (SlideProps | null)[] | null;
  } | null;
};

const Carousel: React.FunctionComponent<CarouselProps> = ({ carousel }) => {
  return (
    <CarouselContainer>
      {carousel?.slides?.map((slide, index) => {
        return (
          <Slide
            description={slide?.description}
            images={slide?.images}
            key={`${index}-${slide?.title}`}
            lazyload={index === 0 ? false : true}
            local={false}
            slug={slide?.slug}
            title={slide?.title}
          />
        );
      })}
    </CarouselContainer>
  );
};

export default React.memo(Carousel);
