import React from 'react';

import { LazyloadBackground, LazyloadBackgroundProps } from 'components/Image';
import RichText from 'components/RichText';

import './styles.scss';

type ContactCtaProps = {
  title?: string | null;
  description?: {
    raw: string | null;
  } | null;
  image?: Omit<LazyloadBackgroundProps, 'local'> | null;
};

const ContactCta: React.FunctionComponent<ContactCtaProps> = ({
  title,
  description,
  image,
}) => {
  return (
    <section className="contact-cta">
      <div>
        <div>
          {title && <h3>{title}</h3>}
          {description && description.raw && (
            <RichText content={description.raw} />
          )}
        </div>
      </div>
      <div>
        {image && <LazyloadBackground image={image.image} local={false} />}
      </div>
    </section>
  );
};

export default React.memo(ContactCta);
