import React, { useCallback, useEffect, useState } from 'react';

import { next, previous } from './utils';

type UseCarousel = {
  active: number;
  onMouseDown: (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => void;
  onMouseUp: (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => void;
  onTouchEnd: () => void;
  onTouchMove: (e: React.TouchEvent<HTMLUListElement>) => void;
  onTouchStart: (e: React.TouchEvent<HTMLUListElement>) => void;
};

export const useCarousel = (length: number, interval: number): UseCarousel => {
  const [active, setActive] = useState(0);
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(0);

  const onMouseDown = useCallback(
    (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
      setStartPosition(e.screenX);
    },
    [],
  );

  const onMouseUp = useCallback(
    (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
      if (startPosition !== e.screenX) {
        if (startPosition < e.screenX) {
          setActive(state => previous(length, state));
        } else {
          setActive(state => next(length, state));
        }
      }
    },
    [length, startPosition],
  );

  const onTouchStart = useCallback((e: React.TouchEvent<HTMLUListElement>) => {
    setStartPosition(e.touches[0].clientX);
    setEndPosition(e.touches[0].clientX);
  }, []);

  const onTouchMove = useCallback((e: React.TouchEvent<HTMLUListElement>) => {
    setEndPosition(e.touches[0].clientX);
  }, []);

  const onTouchEnd = useCallback(() => {
    if (startPosition !== endPosition) {
      if (startPosition < endPosition) {
        setActive(state => previous(length, state));
      } else {
        setActive(state => next(length, state));
      }
    }
  }, [length, endPosition, startPosition]);

  useEffect(() => {
    const id = setTimeout(
      () => setActive(state => next(length, state)),
      interval,
    );
    return () => clearTimeout(id);
  }, [active, interval, length]);

  return {
    active,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
  };
};
