import React, { cloneElement, isValidElement, useMemo } from 'react';

import { useCarousel } from '../hooks';
import BrandText from '../BrandText';
import CarouselItem from '../CarouselItem';

export type CarouselContainerProps = {
  interval?: number;
  children: React.ReactNode;
};

const CarouselContainer: React.FunctionComponent<CarouselContainerProps> = ({
  interval = 5000,
  children,
}) => {
  const slides = useMemo(() => React.Children.toArray(children), [children]);
  const {
    active,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
  } = useCarousel(slides.length, interval);

  return (
    <div className="carousel">
      <ul
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}>
        {slides.map((slide, index) => (
          <CarouselItem active={active === index} key={index}>
            {isValidElement(slide) &&
              cloneElement(slide, {
                ...slide.props,
                active: (active === index).toString(),
              })}
          </CarouselItem>
        ))}
      </ul>
      <BrandText />
    </div>
  );
};

export default React.memo(CarouselContainer);
