import React from 'react';
import clsx from 'clsx';

import { Picture, PictureProps } from 'components/Image';
import Chevron from 'modules/theme/images/carousel/chevron.svg';
import PageLink from 'components/Hyperlink/PageLink';

export type SlideProps = {
  active?: boolean;
  description?: string | null;
  images?: Omit<PictureProps, 'lazyload' | 'local'> | null;
  lazyload?: boolean;
  local?: boolean;
  slug?: string | null;
  title?: string | null;
};

const Slide: React.FunctionComponent<SlideProps> = ({
  active,
  description,
  images,
  lazyload,
  local,
  slug,
  title,
}) => {
  const classNames = clsx(
    'carousel-slide',
    title?.toLowerCase().replace(/ +/g, '-'),
  );

  return (
    <article className={classNames}>
      <Picture
        alt={images?.alt}
        lazyload={lazyload}
        local={local}
        image={images?.image}
      />
      <div className="container">
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
        {slug && (
          <PageLink
            ariaLabel={`View ${title} case study`}
            label="View case study"
            slug={slug}
            tabIndex={active ? 0 : -1}>
            <Chevron />
          </PageLink>
        )}
      </div>
    </article>
  );
};

export default React.memo(Slide);
