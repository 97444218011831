import React from 'react';

import { Image } from 'components/Image';
import CaseStudyItem from './CaseStudyItem';

import './styles.scss';

type CaseStudyCtaProps = {
  largeCardClientName?: string | null;
  largeCardDescription?: {
    largeCardDescription: string | null;
  } | null;
  largeCardImage?: {
    alt: string | null;
    image: readonly (Image | null)[] | null;
  } | null;
  largeCardSlug?: string | null;
  smallCardBottomClientName?: string | null;
  smallCardBottomDescription?: {
    smallCardBottomDescription: string | null;
  } | null;
  smallCardBottomImage?: {
    alt: string | null;
    image: readonly (Image | null)[] | null;
  } | null;
  smallCardBottomSlug?: string | null;
  smallCardTopClientName?: string | null;
  smallCardTopDescription?: {
    smallCardTopDescription: string | null;
  } | null;
  smallCardTopImage?: {
    alt: string | null;
    image: readonly (Image | null)[] | null;
  } | null;
  smallCardTopSlug?: string | null;
};

const CaseStudyCta: React.FunctionComponent<CaseStudyCtaProps> = ({
  largeCardClientName,
  largeCardDescription,
  largeCardImage,
  largeCardSlug,
  smallCardBottomClientName,
  smallCardBottomDescription,
  smallCardBottomImage,
  smallCardBottomSlug,
  smallCardTopClientName,
  smallCardTopDescription,
  smallCardTopImage,
  smallCardTopSlug,
}) => {
  return (
    <div className="case-study-cta">
      <div>
        <div>
          <CaseStudyItem
            clientName={largeCardClientName}
            description={largeCardDescription?.largeCardDescription}
            image={largeCardImage}
            slug={largeCardSlug}
          />
        </div>
        <div>
          <CaseStudyItem
            clientName={smallCardTopClientName}
            description={smallCardTopDescription?.smallCardTopDescription}
            image={smallCardTopImage}
            slug={smallCardTopSlug}
          />
          <CaseStudyItem
            clientName={smallCardBottomClientName}
            description={smallCardBottomDescription?.smallCardBottomDescription}
            image={smallCardBottomImage}
            slug={smallCardBottomSlug}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CaseStudyCta);
