import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import { Picture, PictureProps } from 'components/Image';
import { useIntersectionObserver } from 'modules/hooks/useIntersectionObserver';

import './styles.scss';

type YTVideoProps = {
  placeholderImage?: Omit<PictureProps, 'local'> | null;
  videoId: string;
};

export const YTVideo: React.FunctionComponent<YTVideoProps> = ({
  placeholderImage,
  videoId,
}) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const inView = useIntersectionObserver(elementRef, {
    root: null,
    rootMargin: '50px 0px',
    threshold: 0,
  });
  const classNames = clsx('iframe-container', inView && 'loaded');

  return (
    <div className={classNames}>
      <div onLoad={() => setVideoLoaded(true)} ref={elementRef}>
        {inView && (
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            data-gtm-yt-inspected-6="true"
            frameBorder="0"
            height="360"
            id="ytplayer"
            ref={iframeRef}
            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&amp;mute=1&amp;playsinline=1&amp;playlist=${videoId}&amp;loop=1&amp;showinfo=0&amp;rel=0&amp;modestbranding=1&amp;controls=1&amp;origin=https%3A%2F%2Fsputnik.digital&amp;widget_referrer=https%3A%2F%2Fsputnik.digital&amp;enablejsapi=1&amp;widgetid=1`}
            title="Sputnik Showreel - Fluent Money"
            width="640"
          />
        )}
      </div>
      {!videoLoaded && placeholderImage && (
        <Picture
          alt={placeholderImage?.alt}
          image={placeholderImage?.image}
          lazyload={true}
          local={false}
        />
      )}
    </div>
  );
};

export default React.memo(YTVideo);
