import React from 'react';

import Carousel from 'components/Carousel';
import CaseStudyCta from 'components/CaseStudyCta';
import ContactCta from 'components/ContactCta';
import LogoList from 'components/LogoList';
import Main from 'components/Main';
import ServicesSection from 'components/ServicesSection';
import ShowReel from 'components/ShowReel';

type TemplateProps = Queries.HomepageQuery;

const Template: React.FunctionComponent<TemplateProps> = ({
  contentfulHomepage: props,
}) => {
  return (
    <Main>
      <Carousel {...props?.carouselReference} />
      <ServicesSection {...props?.servicesSectionReference} />
      <ShowReel {...props?.showReelReference} />
      <CaseStudyCta {...props?.caseStudyCtaReference} />
      <LogoList {...props?.logoListReference} />
      <ContactCta {...props?.contactCtaReference} />
    </Main>
  );
};

export default React.memo(Template);
